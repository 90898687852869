<!--
 * @Description: 收款凭证列表
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-08-07 09:39:19
 * @LastEditors: Seven
 * @LastEditTime: 2022-04-27 11:37:13
-->
<template>
  <div class="voucher-list">
    <div ref="contentTop">
      <div style="width: 94%; margin: 0 auto">
        <Search
          :searchAllParam="searchAllParam"
          @search="search"
          @reset="reset"
          :searchParamList="searchParamList"
          code="count"
        />
      </div>
      <div class="line"></div>
    </div>
    <el-row style="width: 94%; margin: 0 auto">
      <el-col>
        <el-table
          highlight-current-row
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          border
          stripe
          :height="tableHeight"
          size="mini"
          v-loading="listLoading"
          element-loading-text="拼命加载中"
          :row-style="{ height: '20px' }"
          :cell-style="{ padding: '0px' }"
        >
          <el-table-column v-if="processInstanceIdShow"
            label="审核业务ID"
            prop="processInstanceId"
            min-width="100"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="凭证单号"
            prop="voucherNo"
            min-width="100"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="标题"
            prop="name"
            min-width="240"
          >
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="学员"
            prop="orderUserName"
            min-width="100"
          >
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="当前节点"
            prop="activityId"
            min-width="120"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.currentTask && scope.row.currentTask.name }}</div>
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="当前办理人"
            prop="assignee"
            min-width="100"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.currentTask && scope.row.currentTask.assigneeName }}</div>
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="审核流程状态"
            prop=""
            min-width="100"
          >
            <template slot-scope="scope">
              <div :style="{color: !scope.row.endTime?scope.row.isSuspended == 1?'red':scope.row.currentTask.activityId!=null && scope.row.currentTask.activityId=='_14'?'':'#337AFF':''}">{{ !scope.row.endTime? scope.row.isSuspended == 1?'中止':scope.row.currentTask.activityId!=null && scope.row.currentTask.activityId=='_14'?'已结束':'流转中':'已结束' }}</div>
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="提交时间"
            prop="startTime"
            min-width="140"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="结束时间"
            prop="endTime"
            min-width="140"
          ></el-table-column>
          <el-table-column
            label="操作"
            min-width="150"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button v-if="!(!scope.row.endTime && scope.row.isSuspended == 1)" type="text" @click="goDetail(scope.row)"
                >详情</el-button
              >
              <el-button type="text" @click="openDetail(scope.row)"
                >审核记录</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="page_test_bottom">
          <el-pagination
            background
            @size-change="sizeChange"
            @current-change="currentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="pageParam.curPage"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageParam.pageSize"
            :total="total"
          />
        </div>
      </el-col>
    </el-row>
    <!-- 详情 -->
    <AuditRecord ref="drawer" />
  </div>
</template>
<script>
import {
  getUserCustomQuery,
} from "@/api/public";
import Search from "@/components/search";
import AuditRecord from "./components/audit-record.vue";
import { mapGetters } from 'vuex';

export default {
  name: "collectionList",
  components: {
    Search,
    AuditRecord,
  },
  data() {
    const searchParamList = ["createTime", "keyword", "status"]
    return {
      tableData: [],
      listLoading: false,
      total: 0,
      pageParam: {
        curPage: 1,
        pageSize: 20,
        tenantId: "ty",
        processDefinitionKey: "hq_receipt"
      },
      searchShow: false,
      searchAllParam: [
        {
          name: "createTime",
          label: "提交时间",
          type: "date",
          value: [],
          ref: "create",
          show: false,
        },
        {
          name: "keyword",
          label: "订单号",
          type: "input",
          value: "",
        },
        {
          name: "status",
          label: "审核状态",
          type: "select",
          value: "",
          option: [{id: 0, name: '流转中'}, {id: 1, name: '已结束' }, {id: 2, name: '中止'}],
          text: "name",
          id: "id",
        },
      ],
      searchParamList: searchParamList,
      defaultParamList: searchParamList,
      searchParamFromApi: [],
      eidtShow: false,
      form: {
        provinceCode: "",
        schoolNcId: "",
        teacherCode: "",
      },
      phoneList: [],
      province: [],
      schoolList: [],
      teacherList: [],
      currCol: null,
      deptList: [],
      // 页面是否有滚动条
      isScroll: true,
      topHeight: 0,
      processInstanceIdShow: false,
    };
  },
  computed: {
    ...mapGetters(["loginUser"]),
    tableHeight() {
      return window.outerHeight - this.topHeight - 256 + 'px'
    }
  },
  mounted() {
    this.getSearchList();
    this.getInstanceList();
    const _this = this
    // 浏览器插件控制审核业务id的显示
    window.checkShow = () => {
      _this.processInstanceIdShow = true
      // 解决列表错位问题
      setTimeout(() => {
        this.$nextTick(() => {
            this.$refs.multipleTable.doLayout()
        })
      }, 300)
    }
  },
  methods: {
    // 更新自定义查询
    searchList() {
      this.getSearchList();
    },
    // 获取自定义查询的默认查询字段
    async getSearchList() {
      this.searchParamList = this.searchAllParam;
      console.log('this.searchParamList', this.searchParamList);
      // const { code, result } = await getUserCustomQuery("collectionList");
      // if (code === 0) {
      //   this.defaultParamList = ["createTime", "keyword", "status"]
      //   if (result && result.fieldData && JSON.parse(result.fieldData).length > 0) {
      //     this.searchParamFromApi = JSON.parse(result.fieldData);
          
      //     this.defaultParamList = []
      //     this.searchParamFromApi.map(() => {
      //       this.defaultParamList.push("")
      //     })
      //     let index
      //     this.searchAllParam.filter((item) => {
      //       index = this.searchParamFromApi.findIndex(i => i === item.name)
      //       this.defaultParamList[index] = item
      //     });
      //   } else {
      //     this.defaultParamList = this.searchAllParam.filter((item) =>
      //       this.defaultParamList.includes(item.name)
      //     );
      //   }
      //   this.searchParamList = this.defaultParamList;
      //   this.$nextTick(() => {
      //     this.topHeight = this.$refs.contentTop.offsetHeight + 44
      //   })
      // }
    },
    // 打开审核记录
    openDetail(row) {
      this.$refs.drawer.init(row)
    },
    // 获取列表数据
    async getInstanceList() {
      if(!this.loginUser.tyUserId) {
        this.$message.error("请先绑定天翼账号！")
        return false
      }
      this.listLoading = true;
      let URL = ""
      this.pageParam.userId = this.loginUser.tyUserId
      switch (this.loginUser.dataScope) {
        case 0:
          URL = "third_getMyInstanceList"
          break;
        case 1:
          URL = "third_getBranchInstanceList"
          break;
        case 2:
          URL = "third_getAllInstanceList"
          delete this.pageParam.userId
          break;
        case 3:
          URL = "third_getBranchInstanceList"
          break;
          default:
      }
      const { code, data, msg, pageInfo } = await this.$fetch(URL, {...this.pageParam, ...this.searchParam})
      if (code === 200) {
        this.tableData = data;
        this.total = pageInfo.total;
        this.$nextTick(() => {
          this.isScroll = document.body.scrollHeight > window.innerHeight
        })
      } else {
        this.$message.error(msg)
      }
      this.listLoading = false;
    },
    // 查询
    search() {
      this.searchParam = {};
      this.searchParamList.map((item) => {
        if (item.value === 0 || item.value) {
          if (item.type === "date") {
            if (item.name === "createTime" && item.value.length) {
              this.searchParam.beginDate = item.value[0] + ' 00:00:00';
              this.searchParam.endDate = item.value[1] + ' 23:59:59';
            }
          } else {
            this.searchParam[item.name] = item.value;
          }
        }
      });
      this.pageParam = {
        curPage: 1,
        pageSize: 20,
        tenantId: "ty",
        processDefinitionKey: "hq_receipt"
      };
      this.getInstanceList();
    },
    // 重置
    reset() {
      this.searchParamList.map((item) => {
        if (item.value || item.value == 0) {
          if(item.name === "createTime") {
            item.value = []
          } else {
            item.value = ""; 
          }
        }
      });
      this.pageParam = {
        curPage: 1,
        pageSize: 20,
        tenantId: "ty",
        processDefinitionKey: "hq_receipt"
      };
      this.search();
    },
    // 改变分页条数
    sizeChange(val) {
      this.pageParam.pageSize = val;
      this.getInstanceList();
    },
    // 改变分页
    currentChange(val) {
      this.pageParam.curPage = val;
      this.getInstanceList();
    },
    // 跳转详情
    goDetail(row) {
      this.$router.push( 
        {
        path: "/afterSale/collection-detail",
        query: {
          id: row.processInstanceId
        }
      });      
    },
  },
};
</script>
<style lang="scss" scoped>
.voucher-list {
  // padding-bottom: 40px;
}
.el-col-24 {
  margin-top: 18px;
}
</style>
