<!--
 * @Description: 审核记录
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-08-07 15:36:57
 * @LastEditors: Seven
 * @LastEditTime: 2021-12-31 16:29:18
-->
<template>
  <HqDrawer
    title="审核记录"
    :visible.sync="drawer"
    size="90%"
    ref="drawer"
  >
    <div class="page-width">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        border
        stripe
        size="mini"
        element-loading-text="拼命加载中"
      >
        <el-table-column :show-overflow-tooltip="true"
          label="提交时间"
          prop="startTime"
          min-width="120"
          
        ></el-table-column>
        <el-table-column :show-overflow-tooltip="true"
          label="业务节点"
          prop="name"
          min-width="120"
          
        >
          <!-- <template slot-scope="scope">
            <div>{{ scope.row.activityId | convert(activeList) }}</div>
          </template> -->
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true"
          label="业务状态"
          prop="isAgree"
          min-width="120"
          
        >
          <template slot-scope="scope">
            <div>{{ scope.row.isAgree | convert(isAgreeList) }}</div>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true"
          label="办理人"
          prop="assigneeName"
          min-width="120"
          
        ></el-table-column>
        <el-table-column :show-overflow-tooltip="true"
          label="办理时间"
          prop="endTime"
          min-width="120"
          
        ></el-table-column>
        <el-table-column :show-overflow-tooltip="true"
          label="办理意见"
          prop="comment"
          min-width="120"
          
        ></el-table-column>
      </el-table>
    </div>
    <div slot="footer">
      <el-button size="small" @click="goBack">返回</el-button>
    </div>
  </HqDrawer>
</template>
<script>
export default {
  data() {
    return {
      drawer: true,
      tableData: [],
      isAgreeList: {
        "1": "同意",
        "0": "不同意",
        "-1": "返回发起人"
      },
      activeList: {
        "_5": "填写凭证信息",
        "_6": "校区出纳审核",
        "_7": "总部财务审核(NC)" 
      }
    }
  },
  methods: {
    init(row) {
      console.log(row);
      this.$refs.drawer.init()
      const params = {
        tenantId: "ty",
        processDefinitionKey: "hq_receipt",
        processInstanceId: row.processInstanceId
      }
      this.$fetch("third_getCustomHistory", params).then(res => {
        if(res.code === 200) {
          this.tableData = res.data
        }
      })
    },
    // 返回
    goBack() {
      this.$refs.drawer.close()
    }
  }
}
</script>
<style lang="scss" scoped>
  .page-width {
    width: 94%;
    margin: 0 auto;
  }
</style>
