import { render, staticRenderFns } from "./audit-record.vue?vue&type=template&id=47bbc14f&scoped=true&"
import script from "./audit-record.vue?vue&type=script&lang=js&"
export * from "./audit-record.vue?vue&type=script&lang=js&"
import style0 from "./audit-record.vue?vue&type=style&index=0&id=47bbc14f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47bbc14f",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\qikecheng_new\\allnet_crm\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('47bbc14f')) {
      api.createRecord('47bbc14f', component.options)
    } else {
      api.reload('47bbc14f', component.options)
    }
    module.hot.accept("./audit-record.vue?vue&type=template&id=47bbc14f&scoped=true&", function () {
      api.rerender('47bbc14f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/order/collection-list/components/audit-record.vue"
export default component.exports