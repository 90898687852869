var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "voucher-list" },
    [
      _c("div", { ref: "contentTop" }, [
        _c(
          "div",
          { staticStyle: { width: "94%", margin: "0 auto" } },
          [
            _c("Search", {
              attrs: {
                searchAllParam: _vm.searchAllParam,
                searchParamList: _vm.searchParamList,
                code: "count"
              },
              on: { search: _vm.search, reset: _vm.reset }
            })
          ],
          1
        ),
        _c("div", { staticClass: "line" })
      ]),
      _c(
        "el-row",
        { staticStyle: { width: "94%", margin: "0 auto" } },
        [
          _c(
            "el-col",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listLoading,
                      expression: "listLoading"
                    }
                  ],
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    "highlight-current-row": "",
                    data: _vm.tableData,
                    "tooltip-effect": "dark",
                    border: "",
                    stripe: "",
                    height: _vm.tableHeight,
                    size: "mini",
                    "element-loading-text": "拼命加载中",
                    "row-style": { height: "20px" },
                    "cell-style": { padding: "0px" }
                  }
                },
                [
                  _vm.processInstanceIdShow
                    ? _c("el-table-column", {
                        attrs: {
                          label: "审核业务ID",
                          prop: "processInstanceId",
                          "min-width": "100"
                        }
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "凭证单号",
                      prop: "voucherNo",
                      "min-width": "100"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "标题",
                      prop: "name",
                      "min-width": "240"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "学员",
                      prop: "orderUserName",
                      "min-width": "100"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "当前节点",
                      prop: "activityId",
                      "min-width": "120"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  scope.row.currentTask &&
                                    scope.row.currentTask.name
                                )
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "当前办理人",
                      prop: "assignee",
                      "min-width": "100"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  scope.row.currentTask &&
                                    scope.row.currentTask.assigneeName
                                )
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "审核流程状态",
                      prop: "",
                      "min-width": "100"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              {
                                style: {
                                  color: !scope.row.endTime
                                    ? scope.row.isSuspended == 1
                                      ? "red"
                                      : scope.row.currentTask.activityId !=
                                          null &&
                                        scope.row.currentTask.activityId ==
                                          "_14"
                                      ? ""
                                      : "#337AFF"
                                    : ""
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    !scope.row.endTime
                                      ? scope.row.isSuspended == 1
                                        ? "中止"
                                        : scope.row.currentTask.activityId !=
                                            null &&
                                          scope.row.currentTask.activityId ==
                                            "_14"
                                        ? "已结束"
                                        : "流转中"
                                      : "已结束"
                                  )
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "提交时间",
                      prop: "startTime",
                      "min-width": "140"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "show-overflow-tooltip": true,
                      label: "结束时间",
                      prop: "endTime",
                      "min-width": "140"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      "min-width": "150",
                      fixed: "right"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            !(!scope.row.endTime && scope.row.isSuspended == 1)
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.goDetail(scope.row)
                                      }
                                    }
                                  },
                                  [_vm._v("详情")]
                                )
                              : _vm._e(),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.openDetail(scope.row)
                                  }
                                }
                              },
                              [_vm._v("审核记录")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "page_test_bottom" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      layout: "total, sizes, prev, pager, next, jumper",
                      "current-page": _vm.pageParam.curPage,
                      "page-sizes": [10, 20, 50, 100],
                      "page-size": _vm.pageParam.pageSize,
                      total: _vm.total
                    },
                    on: {
                      "size-change": _vm.sizeChange,
                      "current-change": _vm.currentChange
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("AuditRecord", { ref: "drawer" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }